import { create } from 'zustand'
import { devtools, persist, createJSONStorage } from 'zustand/middleware'
import { Handler } from '@digital-magic/ts-common-utils'
import { VehicleId } from '@api/endpoints'

type VehicleInfo = Readonly<{ id: VehicleId }>

export type State = Readonly<{
  vehicles: ReadonlyArray<VehicleInfo>
}>

export type Actions = Readonly<{
  addVehicle: Handler<VehicleInfo>
  removeVehicle: Handler<VehicleId>
}>

export const useVehiclesIdCompareStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        vehicles: [],
        addVehicle: (vehicle) =>
          set((state) => {
            if (state.vehicles.find((v) => v.id === vehicle.id)) {
              return state
            } else if (state.vehicles.length <= 2) {
              return { vehicles: state.vehicles.concat(vehicle) }
            } else {
              return { vehicles: state.vehicles.concat(vehicle).slice(1) }
            }
          }),
        removeVehicle: (vehicleId) =>
          set((state) => ({ vehicles: state.vehicles.filter((vehicle) => vehicle.id !== vehicleId) }))
      }),
      {
        name: 'vehicleComparisonList',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
)
